import { parse } from "qs";
import { NavigateFunction } from "react-router-dom";
import moment from "moment-timezone";

export const parsedPath = document.location.pathname.split("/").filter((s) => s) || [];
const parseParams = () =>
    parse(window.location.search, { ignoreQueryPrefix: true }) as { [id: string]: string };

type parseFunction = (s: string) => string | number | boolean | string[] | number[] | null;

function getQueryParam(key: string): string {
    const parsedParams = parseParams();
    return parsedParams[key] || parsedParams[key.toLowerCase()];
}

function getPathParam(key: string): string {
    let value;
    if (parsedPath[0] === "booking") {
        if (key === "system") {
            value = parsedPath[1];
        } else if (key === "webBookingId") {
            value = parsedPath[2];
        }
    } else {
        if (key === "system") {
            value = parsedPath[0];
        } else if (key === "unitId") {
            value = parsedPath[1];
        }
    }
    return value;
}

export const isLandingPage = parsedPath[0] === "booking";
function parseURLParam(
    key: string,
    defaultValue: string | number | boolean | string[] | number[] | null = null,
    parse: parseFunction = (v) => v
): string | number | boolean | string[] | number[] | null {
    const queryParam = getQueryParam(key);
    const pathParam = getPathParam(key);

    const value = pathParam || queryParam || defaultValue;

    return value ? parse(value.toString()) : null;
}
export function setSearch(navigate: NavigateFunction, key: string | {}, value: string = "") {
    const object = typeof key === "string" ? { [key]: value } : key;
    const url = new URL(window.location.toString());
    for (const [key, value] of Object.entries(object)) {
        if (value === "") {
            url.searchParams.delete(key);
        } else {
            url.searchParams.set(key, value);
        }
    }
    navigate(url.pathname + url.search);
}
export function asBool(name: string) {
    return parseURLParam(name, false, (s) => s === "true" || s === "1") as boolean;
}
export function asInt(name: string) {
    const defaultValue: null = null;
    const value = parseURLParam(name, defaultValue, (s) => parseInt(s, 10)) as number;
    return isNaN(value) ? defaultValue : value;
}

export function asIntArray(name: string) {
    const defaultValue: number[] = [];
    return parseURLParam(
        name,
        defaultValue,
        (s) => s && s.split && s.split(",").map((id) => parseInt(id, 10))
    ) as number[];
}
export function asString(
    name: string,
    defaultValue?: string | number | boolean | string[] | number[] | null
) {
    return parseURLParam(name, defaultValue) as string;
}

export function asDate(name: string) {
    const stringDate = parseURLParam(name, null) as string;
    return stringDate ? moment(stringDate).local().startOf("day") : null;
}
