import { Button } from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";
import { useBookingStateContext } from "store";

interface ISubmitButtonProps {
    isDisabled: boolean;
    isLoading: boolean;
}

const SubmitButton = ({ isDisabled, isLoading }: ISubmitButtonProps) => {
    const { t } = useTranslation();
    const { isWaitList } = useBookingStateContext();
    return (
        <Button
            size="lg"
            variant="primary"
            type="submit"
            isLoading={isLoading}
            isDisabled={isDisabled}
            w="100%"
        >
            {isWaitList ? t("waitList.signUpWaitList") : t("book")}
        </Button>
    );
};

export default SubmitButton;
