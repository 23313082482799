import { Alert, Box, Text } from "caspeco-casper-ui";
import { useExternalBookingSettings } from "hooks";
import { Trans } from "react-i18next";

const BookingCancelNotPossibleAlert = () => {
    const externalBookingSettings = useExternalBookingSettings();
    const name = externalBookingSettings.unitsMetaData.name;
    return (
        <Alert type="info">
            <Box>
                <Trans
                    i18nKey="notPossibleToCancelBooking"
                    values={{ name }}
                    components={{ bold: <Text as="span" fontWeight="bold" /> }}
                >
                    <Text as="span" fontWeight="bold" />
                </Trans>
            </Box>
        </Alert>
    );
};

export default BookingCancelNotPossibleAlert;
