import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { dateToString, StateValue } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import { get } from "./axios-base";
import { DayState } from "../../old/models/dayState";

interface Days {
    [n: string]: { available: number[]; waitList: number[]; open: boolean };
}
export function useDayStates(date: Date) {
    const { unitId } = useParams();
    const m = moment(date);

    const startOfMonth = m.startOf("month");
    const today = moment().startOf("day");
    const fromDate = startOfMonth.isAfter(today) ? startOfMonth : today;

    const params = {
        includeAvailableSeats: true,
        includeFirstAvailableMonth: true,
        fromDate: dateToString(fromDate),
        toDate: dateToString(m.add(1, "month").startOf("month")),
        unitId,
    };
    return useQuery({
        queryKey: ["dayStates", m.format("YYYY-MM")],
        queryFn: async () => {
            const response = await get(`/WebBooking/dayStates`, {
                params,
                transformResponse: (data) => {
                    const days: Days = {};

                    JSON.parse(data).forEach((dayState: DayState) => {
                        const key = moment(dayState.day).format("YYYY-MM-DD");
                        if (!days[key]) days[key] = { available: [], waitList: [], open: false };

                        let { available, waitList, open } = days[key];

                        if (dayState.availableSeats)
                            available = [...available, ...dayState.availableSeats];
                        if (dayState.waitListSeats)
                            waitList = [...waitList, ...dayState.waitListSeats];
                        if (dayState.state === StateValue.Open) open = true;
                        days[key] = { available, waitList, open };
                    });

                    return days;
                },
            });
            return response.data as Days;
        },
    });
}
export function getNonAvailableDatesForGuests(days: Days, guests: number) {
    const closedDays: Date[] = [];
    const waitListDays: Date[] = [];
    const fullyBookedDays: Date[] = [];

    Object.entries(days).forEach(([key, { available, waitList, open }]) => {
        if (!open) {
            closedDays.push(new Date(key));
        } else if (!available.includes(guests)) {
            if (waitList.includes(guests)) {
                waitListDays.push(new Date(key));
            } else {
                fullyBookedDays.push(new Date(key));
            }
        }
    });
    return { closedDays, waitListDays, fullyBookedDays };
}
