import {
    Button,
    Divider,
    Flex,
    Icon,
    Icons,
    Text,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";

import moment from "moment";
import { useTranslation } from "react-i18next";

interface IFirstAvailableDayProps {
    date: Date;
    goToNextAvailableDay: () => void;
}

const FirstAvailableDay = ({ date, goToNextAvailableDay }: IFirstAvailableDayProps) => {
    const { t } = useTranslation();
    const formattedDate = moment(date).format("DD MMMM");
    return (
        <>
            <Divider my={ThemeSpaceVariable.Large} />
            <Flex align="flex-end" direction="column" gap={ThemeSpaceVariable.Small}>
                <Text fontSize={ThemeFontSizeVariable.Small} fontWeight="medium">
                    {t("firstAvailableDay", { date: formattedDate })}
                </Text>
                <Button
                    variant="link"
                    gap={ThemeSpaceVariable.XSmall}
                    onClick={goToNextAvailableDay}
                >
                    {t("goToFirstAvailableDay")}
                    <Icon icon={Icons.Forward} fontSize="20px" />
                </Button>
            </Flex>
        </>
    );
};

export default FirstAvailableDay;
