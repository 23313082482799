import { InputGroup } from "@chakra-ui/react";
import { Button, FormControl, Grid, Icons, ThemeSpaceVariable } from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";
import { useBookingActionsContext, useBookingStateContext } from "../../store";
import SelectionHeader from "./selection-header";

export const SelectChildrenAmount = () => {
    const { t } = useTranslation();
    const state = useBookingStateContext();
    const { setChildren } = useBookingActionsContext();

    const { guests } = state;

    const handleGuestsChildrenChange = (guestsChildren: number) => setChildren(guestsChildren);

    return (
        <FormControl key="guest-children" id="guestsChildren">
            <SelectionHeader
                id="guestChildren"
                icon={Icons.Child}
                text={t("start.ofWhichChildren")}
            />
            <InputGroup>
                <Grid
                    templateColumns={{
                        base: "repeat(4, 1fr)",
                        sm: "repeat(4, 1fr)",
                        md: "repeat(5, 1fr)",
                    }}
                    gap={ThemeSpaceVariable.Small}
                    width="100%"
                >
                    {[...Array(guests).keys()].map((children) => {
                        return (
                            <Button
                                key={children}
                                h="56px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                w="100%"
                                gridColumn={!children ? "span 2" : ""}
                                variant="card"
                                onClick={() => handleGuestsChildrenChange(children)}
                            >
                                {!children ? t("start.ofWhichNone") : children}
                            </Button>
                        );
                    })}
                </Grid>
            </InputGroup>
        </FormControl>
    );
};
