import { Heading, ThemeColorVariable, ThemeFontSizeVariable } from "caspeco-casper-ui";
import { SafeHTML } from "components/safe-HTML";
import React from "react";

interface TimeSetTitleProps {
    title: string;
}

const TimeSetTitle = ({ title }: TimeSetTitleProps) => {
    return (
        <Heading
            as="h6"
            color={ThemeColorVariable.OnBackgroundHeader}
            fontSize={ThemeFontSizeVariable.Large}
            fontWeight="500"
        >
            <SafeHTML
                element="span"
                options={{
                    allowedTags: ["a", "br"],
                    allowedSchemes: ["tel", "mailto", "https"],
                }}
                html={title}
            />
        </Heading>
    );
};

export default TimeSetTitle;
