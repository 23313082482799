import { ThemeSpaceVariable, ThemeColorVariable, Text } from "caspeco-casper-ui";
import { SafeHTML } from "components/safe-HTML";
import React from "react";

interface TimeSetCommentProps {
    comment: string;
}

const TimeSetComment = ({ comment }: TimeSetCommentProps) => {
    return (
        <Text mt={ThemeSpaceVariable.XSmall} color={ThemeColorVariable.OnBackgroundSubdued}>
            <SafeHTML
                element="span"
                options={{
                    allowedTags: ["a", "br"],
                    allowedSchemes: ["tel", "mailto", "https"],
                }}
                html={comment.replace(/\n/g, "<br>")}
            />
        </Text>
    );
};

export default TimeSetComment;
