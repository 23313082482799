import { Grid, ThemeSpaceVariable } from "caspeco-casper-ui";
import React from "react";

interface TimeSlotGridProps {
    timeSlots: React.ReactNode[];
}
const TimeSlotGrid = ({ timeSlots }: TimeSlotGridProps) => {
    return (
        <Grid
            templateColumns="repeat(4, 1fr)"
            gap={ThemeSpaceVariable.Small}
            mt={ThemeSpaceVariable.Small}
        >
            {timeSlots}
        </Grid>
    );
};

export default TimeSlotGrid;
