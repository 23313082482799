import { ThemeColorVariable, ThemeSpaceVariable, Text } from "caspeco-casper-ui";
import React from "react";
import { useTranslation } from "react-i18next";

const WaitListTimeSetTitle = () => {
    const { t } = useTranslation();

    return (
        <Text
            color={ThemeColorVariable.OnBackgroundHeader}
            mb={ThemeSpaceVariable.Small}
            fontWeight="500"
        >
            {t("waitList.timesWithWaitList")}
        </Text>
    );
};

export default WaitListTimeSetTitle;
