import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    ThemeColorVariable,
} from "caspeco-casper-ui";
import { validateEmail } from "helpers";
import { ChangeEvent } from "react";
import { Control, UseFormTrigger, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Contact, useBookingActionsContext } from "store";

interface IEmailField {
    control: Control<Contact, any>;
    value: string;
    disabled?: boolean;
    trigger: UseFormTrigger<Contact>;
}

const EmailField = ({ control, value, trigger }: IEmailField) => {
    const { t } = useTranslation();
    const { setContact } = useBookingActionsContext();
    const {
        field,
        fieldState: { error },
    } = useController({
        control,
        name: "email",
        rules: {
            required: t("contact.validation.email"),
            validate: (value) => validateEmail(value),
        },
    });

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        field.onChange(e.target.value);
        if (error) {
            trigger(field.name);
        }
        setContact({ email: value });
    };
    return (
        <FormControl isInvalid={Boolean(error)}>
            <FormLabel>
                {t("contact.email")}{" "}
                <Box as="span" color={ThemeColorVariable.Error}>
                    *
                </Box>
            </FormLabel>
            <Input
                type="email"
                size="lg"
                autoComplete="email"
                name={field.name}
                value={value}
                onChange={handleEmailChange}
                onBlur={field.onBlur}
                ref={field.ref}
            />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};

export default EmailField;
