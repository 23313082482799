import { useCancelBooking } from "hooks/api/cancel-booking";
import { useCreateReservation } from "hooks/api/create-reservation";
import { useFinalizeBooking } from "hooks/api/finalize-booking";
import { LayoutContext } from "layout/layout";
import { trackEvent } from "logging/insights";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Contact, Customer, State, StoreActions } from "types/store";
import { UrlParams, getUrlParams, setSearch } from "utils";
import { WebBookingTimeRuleUnit } from "./hooks/api/types";
const initialState: State = {};
const initialSetState: StoreActions = {};

const BookingStateContext = createContext(initialState);
const BookingSetStateContext = createContext(initialSetState);

export const useBookingStateContext = () => useContext(BookingStateContext);
export const useBookingActionsContext = () => useContext(BookingSetStateContext);

export const GUID_STORAGE_KEY = "reservation-guid";

const getStateFromParams = (params: UrlParams, previousState: State) => {
    const state: State = previousState;
    state.guests = params.guests;
    state.guestsChildren = params.guestsChildren;
    state.date = params.date;

    state.times = params.end
        ? [
              {
                  ruleId: params.ruleId,
                  start: moment(params.start, "HHmm").set({
                      date: params.date.date(),
                      month: params.date.month(),
                  }),
                  end: moment(params.end, "HHmm").set({
                      date: params.date.date(),
                      month: params.date.month(),
                  }),
              },
          ]
        : params.start
          ? params.start.split("-").map((t) => ({
                ruleId: params.ruleId,
                start: moment(t, "HHmm").set({
                    date: params.date.date(),
                    month: params.date.month(),
                }),
            }))
          : null;

    return state;
};

export const BookingProvider = () => {
    const params = getUrlParams();
    const navigate = useNavigate();
    const layoutContext = useContext(LayoutContext);
    const { mutate: cancelBooking } = useCancelBooking();
    const createReservation = useCreateReservation();
    const finalize = useFinalizeBooking();
    const [state, setState] = useState(getStateFromParams(params, {}));

    useEffect(() => {
        setState(getStateFromParams(params, state));
    }, [params, state]);

    useEffect(() => {
        if (params.guests) layoutContext.setShowCoverImage(false);
    }, [layoutContext, params.guests]);

    const { reset } = createReservation;
    useEffect(() => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        if (!(params.start?.length > 0) && guid) {
            cancelBooking(guid);
            reset();
        }
    }, [cancelBooking, params, reset]);

    const setIsWaitList = (isWaitList: boolean) => {
        setState((prevState) => ({
            ...prevState,
            isWaitList: isWaitList,
        }));
    };

    const setGuests = (guests: number) => {
        trackEvent("GuestsSelected", {
            count: guests,
        });
        setState((prevState) => ({
            ...prevState,
            guests,
        }));
        if (params.guests !== guests) {
            setSearch(navigate, "guests", guests.toString());
        }
    };

    const setChildren = (guestsChildren: number) => {
        trackEvent("SelectChildrenAmount", {
            guestsChildren,
            children: guestsChildren,
        });
        setState((prevState) => ({
            ...prevState,
            guestsChildren,
        }));
        if (params.guests !== guestsChildren) {
            setSearch(navigate, "children", guestsChildren.toString());
        }
    };

    const setDate = (date: Date) => {
        setState((prevState) => ({
            ...prevState,
            date: moment(date),
        }));
        const urlDate = moment(date).format("YYYY-MM-DD");
        if (params.date === null || params.date.format("YYYY-MM-DD") !== urlDate)
            setSearch(navigate, "date", urlDate);
    };

    const setContact = (contact: Contact) => {
        setState((prevState) => ({
            ...prevState,
            contact: {
                ...prevState.contact,
                ...contact,
            },
        }));
    };
    const setCustomer = (customer: Customer) => {
        setState((prevState) => ({
            ...prevState,
            customer: {
                ...prevState.customer,
                ...customer,
            },
        }));
    };

    const handleReservation = (state: State) => {
        window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
        window.parent.postMessage("scroll2top", "*");

        setState(state);

        const r = state.times[0].ruleId.toString();
        const start = state.times
            .map((t) => (t as WebBookingTimeRuleUnit).start.format("HHmm"))
            .join("-");
        const end = state.times.length > 1 ? "" : state.times[0].end.format("HHmm");

        setSearch(navigate, { r, start, end });
        createReservation.mutate(state);
    };

    const handleFinalize = () => {
        finalize.mutate(state);
    };

    const storeActions = {
        setState,
        setIsWaitList,
        setGuests,
        setChildren,
        setDate,
        setContact,
        setCustomer,
        cancelBooking,
        handleReservation,
        handleFinalize,
        finalize,
    };
    return (
        <BookingStateContext.Provider value={state}>
            <BookingSetStateContext.Provider value={storeActions}>
                <Outlet />
            </BookingSetStateContext.Provider>
        </BookingStateContext.Provider>
    );
};
