import { BookingStatus } from "api/models/bookingStatus";
import moment from "moment";
import { RecordFactory } from "old/common/modules/recordFactory";

interface IBookingChange {
    changeDate: IMoment;
    status: BookingStatus;
}

const BookingChangeRecord = RecordFactory<IBookingChange>({
    changeDate: moment.invalid(),
    status: BookingStatus.History,
});

export class BookingChange extends BookingChangeRecord {}
