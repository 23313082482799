import { List } from "immutable";
import { WebBooking } from "api/models/webBooking";
import { WebBookingArticle } from "api/models/webBookingArticle";
import { WebBookingTimeRuleUnit } from "old/models/webBookingTimeRuleUnit";
import { WebBookingUnit } from "old/models/webBookingUnit";
export * from "./env-utils";
export * from "./url-params";
export * from "./event-utils";
export * from "../hooks/useDateDisplay";
export * from "../hooks/useMobileView";
export * from "../hooks/useTimeText";
export * from "./datepicker-utils";
export * from "./moment-utils";

/**
 * Checks if the given time slot is only available for waitlisting for the specified number of guests.
 *
 * @param {WebBookingTimeRuleUnit} time - The time slot to check.
 * @param {number} guests - The number of guests to check availability for.
 * @returns {boolean} - Returns `true` if the time slot is only available for waitlisting for the given number of guests, otherwise `false`.
 */
export const isOnlyAvailableForWaitlist = (
    time: WebBookingTimeRuleUnit,
    guests: number
): boolean => {
    return time.waitListSeats?.includes(guests) && !time.availableSeats?.includes(guests);
};

export function isGuid(string: string) {
    return !!string.match(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    );
}

/**
 * Filters and returns unique table articles from a webbooking by combining article group ID and time range.
 *
 * @param {WebBooking} webBooking - The booking data containing articles.
 * @returns {WebBookingArticle[]} Unique table articles based on group ID and time range.
 */
export const getUniqueContainerArticlesByTimeAndGroup = (webBooking: WebBooking) => {
    const uniqueResources = webBooking.articles
        .filter((article) => article.isTable)
        .reduce<{ [key: string]: WebBookingArticle }>((acc, article) => {
            const timeKey = `${article.start.format("HH:mm")}-${article.end.format("HH:mm")}`;
            const groupKey = `${article.articleGroupId}-${timeKey}`;

            if (!acc[groupKey]) {
                acc[groupKey] = article;
            }

            return acc;
        }, {});
    return Object.values(uniqueResources);
};

export const getRuleFromUnitsResult = (unitsResult: List<WebBookingUnit>, ruleId: number) => {
    return unitsResult
        .flatMap((unit) => unit.sections)
        .flatMap((section) => section.timeSets)
        .find((time) => time.id === ruleId);
};
