import { Icons } from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";
import ErrorTemplate from "./error-template";

export default function BrokenLink() {
    const { t } = useTranslation();

    return (
        <ErrorTemplate
            icon={Icons.LinkBroken}
            title={t("errorInformation.brokenLink")}
            message={t("errorInformation.brokenLinkText")}
        />
    );
}
