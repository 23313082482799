import { BookingStatus } from "api/models/bookingStatus";
import { WebBooking } from "api/models/webBooking";
import { List } from "immutable";
import moment, { Moment } from "moment";
import { WaitListOfferStatus } from "old/models/waitListOffer";
import { WebBookingTimeRuleUnit } from "../hooks/api/types";
import { BookedStatus, DayState } from "../old/models/dayState";
import { ExternalBookingSettings } from "old/models/externalBookingSettings";

export const dayStatesToUniqueDates = (dayStates: List<DayState>): Date[] => {
    const uniqueDates: Date[] = [];
    const uniqueDaysSet: Set<string> = new Set();

    dayStates.forEach((dayState: DayState) => {
        const formattedDay = dayState.day.format("YYYY-MM-DD");
        if (!uniqueDaysSet.has(formattedDay)) {
            uniqueDaysSet.add(formattedDay);
            uniqueDates.push(dayState.day.toDate());
        }
    });

    return uniqueDates;
};

export const getDayStatesByDate = (dayStates: List<DayState>, date: Moment): List<DayState> => {
    const matchingDayStates: DayState[] = [];

    dayStates.forEach((dayState: DayState) => {
        if (dayState.day.isSame(date, "day")) {
            matchingDayStates.push(dayState);
        }
    });

    return List(matchingDayStates);
};

// This method only checks the dayState.bookedStatus, it does not check the number of available seats
export const getBookedStatusByDate = (dayStates: List<DayState>, date: Moment): BookedStatus => {
    // grab all dayStates that matches the date
    const matchingDayStates = getDayStatesByDate(dayStates, date);

    // If no seats are available, return FullyBooked
    const noSeatsAvailable = matchingDayStates.every(
        (dayState) => dayState.bookedStatus === BookedStatus.FullyBooked
    );

    // If no seats are available, but there are waitlist seats, return WaitList
    const hasWaitlistSeats = matchingDayStates.some(
        (dayState) => dayState.bookedStatus === BookedStatus.WaitList
    );

    // If there are available seats, return Available
    const hasAvailableSeats = matchingDayStates.some(
        (dayState) => dayState.bookedStatus === BookedStatus.Available
    );

    if (noSeatsAvailable && !hasWaitlistSeats) {
        return BookedStatus.FullyBooked;
    }
    if (hasWaitlistSeats) {
        return BookedStatus.WaitList;
    }

    if (hasAvailableSeats) {
        return BookedStatus.Available;
    }

    return BookedStatus.Undefined;
};

// This method checks dayState.availableSeats and dayState.waitlistSeats
export const getBookedStatusByGuestCount = (
    dayStates: List<DayState>,
    date: Moment,
    nrOfGuests: number
): BookedStatus => {
    const matchingDayStates = getDayStatesByDate(dayStates, date);

    const hasAvailableSeats = matchingDayStates.some(
        (dayState) => dayState.availableSeats && dayState.availableSeats.includes(nrOfGuests)
    );

    const hasWaitlistSeats = matchingDayStates.some((dayState) => {
        const hasWaitList = !!dayState.waitListSeats;
        if (hasWaitList) {
            return dayState.waitListSeats.includes(nrOfGuests);
        }
        return false;
    });

    const allIsUndefined = matchingDayStates.every(
        (dayState) => dayState.bookedStatus === BookedStatus.Undefined
    );

    if (hasAvailableSeats) {
        return BookedStatus.Available;
    }

    if (hasWaitlistSeats) {
        return BookedStatus.WaitList;
    }

    if (allIsUndefined) {
        return BookedStatus.Undefined;
    }

    return BookedStatus.FullyBooked;
};

export const groupedTimes = (times: WebBookingTimeRuleUnit[]) =>
    times.reduce((acc: { [key: string]: WebBookingTimeRuleUnit[] }, time) => {
        if (time.unavailability !== null || !time.disabled) {
            const hour = time.start.format("HH");
            if (!acc[hour]) {
                acc[hour] = [];
            }
            acc[hour].push(time);
        }
        return acc;
    }, {});

export const getSortedHourKeys = (hours: string[]) =>
    hours.sort((a, b) => {
        // TODO: Change this to the actual opening hour
        const openingHour = 6;
        const numA = parseInt(a, 10);
        const numB = parseInt(b, 10);

        // Compare hours taking into account the opening hour
        if (numA < openingHour && numB >= openingHour) {
            return 1;
        }
        if (numB < openingHour && numA >= openingHour) {
            return -1;
        }
        return numA - numB;
    });

export function isWaitList(booking: WebBooking) {
    if (!booking) return false;

    if (booking.history) {
        const lastStatusBeforeCancel = booking.history
            .filter((x) => x.status !== BookingStatus.Canceled)
            .last().status;
        return (
            booking.status === BookingStatus.WaitList ||
            lastStatusBeforeCancel === BookingStatus.WaitList
        );
    }
    return booking.status === BookingStatus.WaitList;
}

export function hasBookingPassed(booking: WebBooking) {
    return moment() > booking?.start;
}

export function isBookingCanceled(booking: WebBooking) {
    return booking?.status === BookingStatus.Canceled;
}

export function canceAllowed(booking: WebBooking, settings: ExternalBookingSettings) {
    if (!booking) return false;
    return !(
        booking.cancelAllowedBeforeUtc &&
        moment() > booking.cancelAllowedBeforeUtc &&
        !isWaitList(booking) &&
        !settings.disableCancel &&
        booking.status !== BookingStatus.Canceled &&
        !hasBookingPassed(booking)
    );
}

export function hasWaitListOffers(booking: WebBooking) {
    if (!booking) return false;
    return booking.waitListOffers && booking.waitListOffers.count() > 0;
}

export function hasAvailableWaitListOffers(booking: WebBooking) {
    if (!booking) return false;
    return (
        booking.waitListOffers &&
        booking.waitListOffers.count() > 0 &&
        booking.waitListOffers.some((w) => w.offerStatus === 1)
    );
}

export function getAvailableWaitListOffer(booking: WebBooking) {
    return booking.waitListOffers?.find(
        (offer) => offer.offerStatus === WaitListOfferStatus.Available
    );
}

export function isWaitListOfferLocked(booking: WebBooking) {
    if (!booking) return false;
    const webTimeRule = booking.webTimeRules?.first();
    if (!webTimeRule) return false;
    const ruleStartTime = moment(booking.start).startOf("day").add(webTimeRule?.startTime, "hours");
    const offerStartTime = getAvailableWaitListOffer(booking)?.startTime;

    if (!offerStartTime) return false;

    const availableUntil =
        webTimeRule?.lockPerTime || webTimeRule?.lockTime === 0
            ? offerStartTime.clone().subtract(webTimeRule?.lockTime, "minutes")
            : ruleStartTime.clone().subtract(webTimeRule?.lockTime, "minutes");
    const now = moment();

    return now.isAfter(availableUntil);
}
