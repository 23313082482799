export enum BookingStatus {
    Confirmed = 102,
    History = 4,
    Canceled = 7,
    WaitList = 9,
    Web = 100,

    Tentative = 101,
    Arrived = 103,
    Placed = 104,
    Appetizer = 105,
    MainCourse = 106,
    Dessert = 107,
    Paid = 108,
    Left = 109,
    NoShow = 110,
    InternalBooking = 111,

    BookingInProgress = 112,
    Expired = 113,
    PaymentFailed = 114,
    PaymentRefundFailed = 115,

    ReadyForInvoicing = 116,

    ConferenceCalc = 0,
    ConferenceOffered = 1,
    ConferencePreliminary = 2,
    ConferenceTentative = 3,
    ConferenceConfirmed = 5,
    ConferenceInvoiced = 6,
    ConferenceClosed = 8,
    ConferencePayed = 10,
    NotStarted = 198,
    NotCheckedIn = 200,
    CheckedIn = 201,
}
