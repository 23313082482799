import { ArticleStock } from "old/models/articleStock";
import * as WebBookingActions from "old/actions/webBookingActions";
import { BackFromPaymentTerminalParameters } from "old/models/backFromPaymentTerminalParameters";
import { DayState } from "old/models/dayState";
import { WebBooking } from "old/models/webBooking";
import { WebBookingCancelParameters } from "old/models/webBookingCancelParameters";
import { WebBookingContactCustomerConnection } from "old/models/webBookingContactCustomerConnection";
import { WebBookingCreateParameters } from "old/models/webBookingCreateParameters";
import { WebBookingFinalizeParameters } from "old/models/webBookingFinalizeParameters";
import { WebBookingPaymentTerminalParameters } from "old/models/webBookingPaymentTerminalParameters";
import { WebBookingUnavailability } from "old/models/webBookingUnavailability";
import { WebBookingUnit } from "old/models/webBookingUnit";
import { ValidationResult } from "old/common/models/validation/validationResult";
import IAPISettings from "old/framework/models/IAPISettings";
import * as API from "old/framework/modules/unauthedAPIBase";
import { List, Map } from "immutable";
import { dateTimeToString, dateToString } from "../../utils";

export type AvailableTimesTime<T = string> = {
    availableSeats: number[];
    waitListSeats: number[];
    availableUntil: T;
    disabled?: boolean;
    isWaitList: boolean;
    end: T;
    start: T;
    unavailability: WebBookingUnavailability[];
};

export enum Promises {
    Reservation = "RESERVATION",
    Cancel = "CANCEL",
}

// A map to track active promises
let activePromises: Map<string, Promise<any>> = Map();

export function getActivePromise(key: string): Promise<any> | undefined {
    return activePromises.get(key);
}

export function setActivePromise(key: string, promise: Promise<any>): void {
    activePromises = activePromises.set(key, promise);
}

export function removeActivePromise(key: string): void {
    activePromises = activePromises.delete(key);
}

export type AvailableTimesTimeSet<T> = {
    conditions: string;
    date: string;
    eventReference: string;
    id: number;
    isClosed: boolean;
    maxGroupSize: number;
    maximumConcurrentBookings: number;
    maximumConcurrentSeats: number;
    minGroupSize: number;
    payment: number;
    paymentAmount: number;
    paymentCurrency: string;
    paymentModifier: number;
    paymentVAT: number;
    recoupTime: number;
    tag: string;
    times: AvailableTimesTime<T>[];
    title: string;
    userType: number;
};

type AvailableTimesSection<T> = {
    hidden: number;
    id: number;
    name: string;
    timeSets: AvailableTimesTimeSet<T>[];
};
export type AvailableTimesUnit<T = string> = {
    id: number;
    name: string;
    timeZone: string;
    sections: AvailableTimesSection<T>[];
};
type AvailableTimesResponseData = AvailableTimesUnit[];

interface IWebBookingAPI {
    loadContactCustomer(customerFriendlyId: string, system: string): void;
    backFromTerminal(
        guid: string,
        parameters: BackFromPaymentTerminalParameters,
        system: string
    ): void;
    cancelWebBooking(
        guid: string,
        parameters: WebBookingCancelParameters,
        system: string
    ): Promise<WebBooking>;
    createBooking(parameters: WebBookingCreateParameters, system: string): Promise<WebBooking>;
    finalizeBooking(
        guid: string,
        parameters: WebBookingFinalizeParameters,
        system: string
    ): Promise<WebBooking>;
    loadArticleStocks(
        articleIds: number[],
        validityDate: IMoment,
        system: string,
        includeArticleStockChanges?: boolean
    ): Promise<Map<number, ArticleStock>>;
    loadAvailableTimes(
        dates: List<IMoment>,
        unitIds: List<number>,
        sectionIds: List<number>,
        system: string,
        includeUnavailability: boolean
    ): Promise<List<WebBookingUnit>>;
    loadDayStates(
        fromDate: IMoment,
        toDate: IMoment,
        unitId: number,
        system: string
    ): Promise<List<DayState>>;
    loadTerminalUrl(
        guid: string,
        parameters: WebBookingPaymentTerminalParameters,
        system: string
    ): void;
    loadWebBooking(bookingPublicId: string, system: string): void;
    loadReservation(bookingPublicId: string, system: string): void;
    refreshWebBooking(guid: string, system: string): Promise<WebBooking>;
}

class WebBookingAPI extends API.UnauthedAPIBase implements IWebBookingAPI {
    constructor() {
        super();
        this.setApiVersion("v1");
    }

    loadContactCustomer(
        customerFriendlyId: string,
        system: string
    ): Promise<WebBookingContactCustomerConnection> {
        return new Promise<WebBookingContactCustomerConnection>(
            (
                success: (
                    webBookingContactCustomerConnection: WebBookingContactCustomerConnection
                ) => void,
                reject: (errorObj: any) => void
            ) => {
                this.get(
                    `WebBooking/WebBookings/getCustomerContactByFriendlyId/${customerFriendlyId}`,
                    { params: [], headers: { System: system } }
                )
                    .then((result) => {
                        const data = result.data;
                        success(new WebBookingContactCustomerConnection(data));
                    })
                    .catch((errorObj) => {
                        console.error(errorObj);
                        reject({ success: false, error: errorObj });
                    });
            }
        );
    }

    loadAvailableTimes(
        dates: List<IMoment>,
        unitIds: List<number>,
        sectionIds: List<number>,
        system: string,
        includeUnavailability: boolean,
        settings?: IAPISettings
    ): Promise<List<WebBookingUnit>> {
        return new Promise<List<WebBookingUnit>>(
            (
                success: (webBookingUnitList: List<WebBookingUnit>) => void,
                reject: (errorObj: any) => void
            ) => {
                const startJSON = dates.map((date) => `date=${dateToString(date)}`).join("&");
                let querystring = `webBooking/AvailableTimes?${startJSON}`;

                unitIds.forEach((unitId) => {
                    querystring += `&unitId=${unitId}`;
                });

                sectionIds.forEach((sectionId) => {
                    querystring += `&sectionId=${sectionId}`;
                });

                if (includeUnavailability) {
                    querystring += "&includeUnavailability=true";
                }

                this.get(querystring, { headers: { System: system } }, settings)
                    .then((result) => {
                        const data: AvailableTimesResponseData = result.data;
                        const units: WebBookingUnit[] = [];

                        // @ts-ignore
                        data.forEach((u) => units.push(new WebBookingUnit(u)));
                        success(List(units));
                    })
                    .catch((errorObj) => {
                        reject({ success: false, error: errorObj });
                        console.error(errorObj);
                    });
            }
        );
    }

    loadWebBooking(bookingPublicId: string, system: string) {
        this.get("webBooking/webBookings/{0}", {
            params: [bookingPublicId],
            headers: { System: system },
        })
            .then((result) => {
                const data = result.data;
                new WebBookingActions.LoadedWebBookingSuccessfully(new WebBooking(data));
            })
            .catch((errorObj) => {
                new WebBookingActions.LoadWebBookingFailed();
            });
    }

    loadReservation(bookingPublicId: string, system: string) {
        this.get("webBooking/webBookings/{0}", {
            params: [bookingPublicId],
            headers: { System: system },
        })
            .then((result) => {
                const data = result.data;
                new WebBookingActions.LoadedReservationSuccessfully(new WebBooking(data));
            })
            .catch((errorObj) => {
                new WebBookingActions.LoadReservationFailed();
            });
    }

    createBooking(
        parameters: WebBookingCreateParameters,
        system: string,
        settings?: IAPISettings
    ): Promise<WebBooking> {
        const querystring = "WebBooking/WebBookings";
        const promise = new Promise<WebBooking>((resolve, reject) => {
            this.post(
                querystring,
                { data: parameters.toJS(), headers: { System: system } },
                settings
            )
                .then((result: any) => {
                    const data = result.data;
                    resolve(new WebBooking(data));
                    // Remove from active promises when done
                    removeActivePromise(Promises.Reservation);
                })
                .catch((errorObj: any) => {
                    console.error(errorObj);
                    reject({ success: false, error: errorObj });
                    new WebBookingActions.ReserveBookingFail(errorObj?.error);
                    // Remove from active promises on error
                    removeActivePromise(Promises.Reservation);
                });
        });
        setActivePromise(Promises.Reservation, promise);
        return promise;
    }

    loadTerminalUrl(guid: string, parameters: WebBookingPaymentTerminalParameters, system: string) {
        const querystring = `webBooking/webBookings/${guid}/PaymentTerminal`;
        this.post(querystring, { data: parameters, headers: { System: system } })
            .then((result) => {
                const data = result.data;
                new WebBookingActions.LoadedTerminalUrlSuccessfully(data);
            })
            .catch((errorObj) => {
                console.error(errorObj);
                new WebBookingActions.LoadTerminalUrlFailed();
            });
    }

    backFromTerminal(guid: string, parameters: BackFromPaymentTerminalParameters, system: string) {
        return new Promise<WebBooking>(
            (success: (webBooking: WebBooking) => void, reject: (errorObj: any) => void) => {
                const querystring = `webBooking/webBookings/${guid}/BackFromPaymentTerminal`;

                this.post(querystring, { data: parameters, headers: { System: system } })
                    .then((result) => {
                        const marcResult = result.data;
                        if (marcResult.validationResult) {
                            const validationResult = new ValidationResult(
                                marcResult.validationResult
                            );
                            if (!validationResult.isValid()) reject(validationResult);
                            //new InvoiceInformationActions.CreateFailed(invoiceInformation, marcResult.validationResult);
                        }
                        if (marcResult.result) {
                            success(new WebBooking(marcResult.result));
                        }
                    })
                    .catch((errorObj) => {
                        console.error(errorObj);
                        reject(errorObj);
                        //new WebBookingActions.BackFromTerminalFailed();
                    });
            }
        );
    }

    finalizeBooking(
        guid: string,
        parameters: WebBookingFinalizeParameters,
        system: string
    ): Promise<WebBooking> {
        return new Promise<WebBooking>(
            (success: (webBooking: WebBooking) => void, reject: (errorObj: any) => void) => {
                const querystring = `WebBooking/WebBookings/${guid}/Finalize`;

                this.post(querystring, { data: parameters, headers: { System: system } })
                    .then((result: any) => {
                        const data = result.data;
                        success(new WebBooking(data));
                    })
                    .catch((errorObj: any) => {
                        console.error(errorObj);
                        reject({ success: false, error: errorObj });
                    });
            }
        );
    }

    cancelWebBooking(
        guid: string,
        parameters: WebBookingCancelParameters,
        system: string
    ): Promise<WebBooking> {
        const promise = new Promise<WebBooking>(
            (success: (webBooking: WebBooking) => void, reject: (errorObj: any) => void) => {
                const querystring = `WebBooking/WebBookings/${guid}/Cancel`;
                this.post(querystring, { data: parameters, headers: { System: system } })
                    .then((result) => {
                        const data = result.data;
                        success(new WebBooking(data));
                        removeActivePromise(Promises.Cancel);
                    })
                    .catch((errorObj: any) => {
                        reject({ success: false, error: errorObj });
                        removeActivePromise(Promises.Cancel);
                    });
            }
        );
        setActivePromise(Promises.Cancel, promise);
        return promise;
    }

    refreshWebBooking(guid: string, system: string, settings?: IAPISettings): Promise<WebBooking> {
        return new Promise<WebBooking>(
            (success: (webBooking: WebBooking) => void, reject: (errorObj: any) => void) => {
                const querystring = `WebBooking/WebBookings/${guid}/Refresh`;

                this.post(querystring, { headers: { System: system } }, settings)
                    .then((result) => {
                        const data = result.data;
                        success(new WebBooking(data));
                    })
                    .catch((errorObj: any) => {
                        reject({ success: false, error: errorObj });
                    });
            }
        );
    }

    loadDayStates = (fromDate: IMoment, toDate: IMoment, unitId: Number, system: string) => {
        const endPoint =
            `WebBooking/DayStates` +
            `?includeAvailableSeats=true`+
            `&includeFirstAvailableMonth=true` +
            `&fromDate=${dateToString(fromDate)}` +
            `&toDate=${dateToString(toDate)}` +
            `&unitId=${unitId}`;
        const mapper = (data: any[]) => List<DayState>(data.map((u) => new DayState(u)));
        const options = {
            headers: { System: system },
        };

        return this.getAsync(endPoint, mapper, options);
    };

    loadArticleStocks = (
        articleIds: number[],
        validityDate: IMoment,
        system: string,
        includeArticleStockChanges?: boolean
    ) => {
        if (!articleIds.length) {
            return Promise.resolve(Map<number, ArticleStock>());
        }

        return this.getAsync(
            "WebBooking/WebBookings/articleStocks?articleIds={0}&includeArticleStockChanges={1}&validityDate={2}",
            (data) => Map<number, ArticleStock>(data.map((a: any) => [a.id, new ArticleStock(a)])),
            {
                headers: { System: system },
                params: [
                    articleIds.join(","),
                    includeArticleStockChanges.toString(),
                    dateTimeToString(validityDate),
                ],
            }
        );
    };
}

const webBookingAPI = new WebBookingAPI();
export default webBookingAPI;
