import { DayState } from "old/models/dayState";
import { WebBooking } from "old/models/webBooking";
import { WebBookingContactCustomerConnection } from "old/models/webBookingContactCustomerConnection";
import { WebBookingError } from "old/models/webBookingError";
import { WebBookingUnit } from "old/models/webBookingUnit";
import { ValidationResult } from "old/common/models/validation/validationResult";
import Action from "old/framework/actions/action";
import { List } from "immutable";

export class LoadingAvailableTimesFailed extends Action {
    static actionType: string = "webBookingUnits_LoadingAvailableTimesFailed";

    constructor(
        public dates: List<IMoment>,
        public unitIds: List<number>,
        public sectionIds: List<number>
    ) {
        super(false);
        this.doDispatch();
    }
}

export class LoadingAvailableTimes extends Action {
    static actionType: string = "webBookingUnits_LoadingAvailableTimes";

    constructor(
        public dates: List<IMoment>,
        public unitIds: List<number>,
        public sectionIds: List<number>
    ) {
        super(false);
        this.doDispatch();
    }
}

export class LoadedAvailableTimesSuccessfully extends Action {
    static actionType: string = "webBookingUnits_loadedAvailableTimesSuccessfully";

    constructor(
        public webBookingUnits: List<WebBookingUnit>,
        public dates: List<IMoment>,
        public unitIds: List<number>,
        public sectionIds: List<number>
    ) {
        super(false);
        this.doDispatch();
    }
}

export class LoadingWebBookingDayStates extends Action {
    static actionType: string = "webBookingUnits_LoadingWebBookingDayStates";

    constructor(
        public fromDate: IMoment,
        public toDate: IMoment,
        public unitIds: number
    ) {
        super(false);
        this.doDispatch();
    }
}

export class LoadedDayStatesSuccessfully extends Action {
    static actionType: string = "webBookingUnits_loadedDayStatesSuccessfully";

    constructor(
        public dayStates: List<DayState>,
        public fromDate: IMoment,
        public toDate: IMoment,
        public unitId: number
    ) {
        super(false);
        this.doDispatch();
    }
}

export class LoadingDayStatesFailed extends Action {
    static actionType: string = "webBookingUnits_LoadingDayStatesFailed";

    constructor(
        public fromDate: IMoment,
        public toDate: IMoment,
        public unitId: number
    ) {
        super(false);
        this.doDispatch();
    }
}

export class LoadedWebBookingSuccessfully extends Action {
    static actionType: string = "webBooking_LoadedWebBookingSuccessfully";

    constructor(public webBooking: WebBooking) {
        super(false);
        this.doDispatch();
    }
}

export class LoadWebBookingFailed extends Action {
    static actionType: string = "webBooking_LoadWebBookingFailed";

    constructor() {
        super();
    }
}
export class LoadedReservationSuccessfully extends Action {
    static actionType: string = "webBooking_LoadedReservationSuccessfully";

    constructor(public webBooking: WebBooking) {
        super(false);
        this.doDispatch();
    }
}

export class LoadReservationFailed extends Action {
    static actionType: string = "webBooking_LoadReservationFailed";

    constructor() {
        super();
    }
}

export class BackFromTerminalSuccessfully extends Action {
    static actionType: string = "webBooking_BackFromTerminalSuccessfully";

    constructor(public webBooking: WebBooking) {
        super(false);
        this.doDispatch();
    }
}

export class BackFromTerminalFailed extends Action {
    static actionType: string = "BackFromTerminalFailed";

    constructor(public validationResult: ValidationResult) {
        super(false);
        this.doDispatch();
    }
}

export class LoadedTerminalUrlSuccessfully extends Action {
    static actionType: string = "LoadedTerminalUrlSuccessfully";

    constructor(public terminalUrl: string) {
        super(false);
        this.doDispatch();
    }
}

export class LoadTerminalUrlFailed extends Action {
    static actionType: string = "webBooking_LoadTerminalUrlFailed";

    constructor() {
        super();
    }
}

export class RefreshedBookingSuccessfully extends Action {
    static actionType: string = "webBooking_RefreshedBookingSuccessfully";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class RefreshBookingFailed extends Action {
    static actionType: string = "webBooking_RefreshBookingFailed";

    constructor() {
        super();
    }
}

export class ReserveBooking extends Action {
    static actionType: string = "webBooking_ReserveBooking";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class ReserveBookingSuccess extends Action {
    static actionType: string = "webBooking_ReserveBookingSuccess";

    constructor(public webBooking: WebBooking) {
        super(false);
        this.doDispatch();
    }
}

export class ReserveBookingFail extends Action {
    static actionType: string = "webBooking_ReserveBookingFail";

    constructor(public webBookingError?: WebBookingError) {
        super(false);
        this.doDispatch();
    }
}

export class CancelBooking extends Action {
    static actionType: string = "webBooking_CancelBooking";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class CancelBookingSuccess extends Action {
    static actionType: string = "webBooking_CancelBookingSuccess";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class CancelBookingFail extends Action {
    static actionType: string = "webBooking_CancelBookingFail";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class FinalizeWebBooking extends Action {
    static actionType: string = "webBooking_FinalizeWebBooking";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class FinalizeWebBookingSuccess extends Action {
    static actionType: string = "webBooking_FinalizeWebBookingSuccess";

    constructor(public webBooking: WebBooking) {
        super(false);
        this.doDispatch();
    }
}

export class FinalizeWebBookingFailed extends Action {
    static actionType: string = "webBooking_FinalizeWebBookingFailed";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class LoadedCustomerContactSuccess extends Action {
    static actionType: string = "webBooking_LoadedCustomerContactSuccess";

    constructor(public webBookingContactCustomerConnection: WebBookingContactCustomerConnection) {
        super(false);
        this.doDispatch();
    }
}

export class LoadedCustomerContactFailed extends Action {
    static actionType: string = "webBooking_LoadedCustomerContactFailed";

    constructor() {
        super(false);
        this.doDispatch();
    }
}
