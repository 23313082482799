import {
    Text,
    Box,
    Flex,
    Heading,
    Icon,
    Icons,
    Stack,
    ThemeSpaceVariable,
    ThemeFontSizeVariable,
} from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

export function WaitListSuccess() {
    const { t } = useTranslation();

    return (
        <Stack alignItems="center" gap={ThemeSpaceVariable.Medium}>
            <Flex
                direction="column"
                alignItems="center"
                gap={ThemeSpaceVariable.Medium}
                py={ThemeSpaceVariable.Medium}
            >
                <Box backgroundColor="#25AF72" padding="2px" borderRadius="50%" marginBottom="16px">
                    <Icon icon={Icons.ModuleSurvey} fontSize="60px" color="white" />
                </Box>
                <Heading as="h1" maxW="340" fontWeight="500" textAlign="center">
                    {t("waitList.waitListSuccessTitle")}
                </Heading>
                <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                    {t("waitList.waitListSuccessMessage")}
                </Text>
            </Flex>
        </Stack>
    );
}
