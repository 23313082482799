import { useWebBooking } from "api/apiHooks";
import {
    Divider,
    Flex,
    Stack,
    Text,
    ThemeColorVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import Price from "components/price";
import { useAvailableTimes } from "hooks/api";
import { SpaceBetween } from "layout/space-between";
import { BookingSummaryItem } from "pages/booking-details/booking-summary/booking-summary-list-item";
import { useTranslation } from "react-i18next";
import { useBookingStateContext } from "store";
import { getUniqueContainerArticlesByTimeAndGroup } from "utils";
import { getAvailableWaitListOffer, hasAvailableWaitListOffers, isWaitList } from "utils/booking";
import { getRuleById } from "utils/time-rule-utils";
import BookingMessage from "./booking-message";

export const BookingSummary = () => {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const { date, times } = useBookingStateContext();
    const availableTimes = useAvailableTimes(date);

    const hasAvailableOffers = hasAvailableWaitListOffers(webBooking);
    const isWaitListBooking = isWaitList(webBooking);

    const contactName = `${webBooking?.contact.fname} ${webBooking?.contact.lname}`;
    const startTimes = webBooking?.articles
        .filter((art) => art.isTable)
        .sort((a, b) => a.start.valueOf() - b.start.valueOf())
        .map((time) => time.start.format("HH:mm"))
        .join(", ");

    const uniqueResources = getUniqueContainerArticlesByTimeAndGroup(webBooking);
    let filteredResources = uniqueResources;
    if (hasAvailableOffers) {
        const offer = getAvailableWaitListOffer(webBooking).startTime;
        filteredResources = uniqueResources.filter((article) => article.start.isSame(offer));
    }

    const showWaitListSummary = isWaitListBooking && !hasAvailableOffers;
    const recoupTimeMinutes = webBooking.recoupTime;

    const ruleById = getRuleById(times ? times[0]?.ruleId : -1, availableTimes.data);
    const ruleName = ruleById
        ? ruleById.title.toLowerCase()
        : webBooking.webTimeRules?.first()?.name.toLowerCase();
    return (
        <Stack
            bg={ThemeColorVariable.SurfaceDisabled}
            p={ThemeSpaceVariable.Medium}
            borderRadius={ThemeRadiusVariable.Medium}
        >
            {showWaitListSummary ? (
                <>
                    <Text fontWeight="medium">
                        {t("waitList.waitListFor", {
                            webTimeRuleName: ruleName,
                        })}
                    </Text>
                    <BookingSummaryItem label={t("startTime")} value={startTimes} />
                </>
            ) : (
                <>
                    <SpaceBetween>
                        <Text fontWeight="500">{t("summary.globalBookingNumber")}</Text>
                        <Text>{webBooking?.globalBookingNumber}</Text>
                    </SpaceBetween>
                    <Divider />
                    <Text fontWeight="medium">{t("bookingDetails")}</Text>
                    <Flex
                        gap={ThemeSpaceVariable.XSmall}
                        direction="column"
                        px={ThemeSpaceVariable.XSmall}
                    >
                        <BookingSummaryItem label={t("start.persons")} value={webBooking?.guests} />
                        <BookingSummaryItem
                            label={t("date")}
                            value={webBooking?.start.format("ddd D MMMM")}
                        />
                        {filteredResources.map((article) => {
                            return (
                                <BookingSummaryItem
                                    key={article.start.toString()}
                                    label={article.articleGroupName}
                                    value={`${article.start.format("HH:mm")} - ${article.end.clone().subtract(recoupTimeMinutes, "minutes").format("HH:mm")}`}
                                />
                            );
                        })}
                    </Flex>
                </>
            )}

            <Divider />
            {webBooking?.chargePayedAmount && (
                <BookingSummaryItem label={t("summary.prepaidAmount")}>
                    <Price amount={webBooking?.chargePayedAmount} display="symbol" />
                </BookingSummaryItem>
            )}
            <Text fontWeight="medium">{t("guestInfromation")}</Text>
            <Stack
                px={ThemeSpaceVariable.XSmall}
                spacing={ThemeSpaceVariable.XSmall}
                alignItems="start"
            >
                {webBooking.customer?.name && <Text>{webBooking?.customer.name}</Text>}
                <Text>{contactName}</Text>
                <Text>{webBooking.contact?.email}</Text>
                {<Text>{webBooking.contact?.mobile}</Text>}
            </Stack>
            {!!webBooking?.message && (
                <>
                    <Divider />
                    <BookingMessage message={webBooking?.message} />
                </>
            )}
        </Stack>
    );
};
