import { Box, Skeleton, Stack, ThemeSpaceVariable } from "caspeco-casper-ui";

function SelectDateSkeleton() {
    return (
        <Box p={ThemeSpaceVariable.XSmall}>
            <Stack direction="row" justifyContent="space-between" my={ThemeSpaceVariable.Large}>
                <Skeleton borderRadius="full" height="50px" width="50px" />
                <Skeleton alignSelf="center" height="30px" width="120px" />
                <Skeleton borderRadius="full" height="50px" width="50px" />
            </Stack>

            <Stack direction="row" justifyContent="space-between" mb={"2"}>
                {Array.from({ length: 7 }).map((_, i) => (
                    <Skeleton key={i} height="20px" width="full" />
                ))}
            </Stack>

            <Stack direction="column">
                {Array.from({ length: 5 }).map((_, weekIdx) => (
                    <Stack key={weekIdx} direction="row" justifyContent="space-between">
                        {Array.from({ length: 7 }).map((_, dayIdx) => (
                            <Skeleton key={dayIdx} height="52px" width="full" />
                        ))}
                    </Stack>
                ))}
            </Stack>

            <Stack mt={ThemeSpaceVariable.Large} direction="row" alignItems="center">
                <Skeleton borderRadius="full" height="8px" width="8px" mr="2" />
                <Skeleton height="15px" width="120px" />
            </Stack>
        </Box>
    );
}

export default SelectDateSkeleton;
