import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Text,
    Textarea,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { SafeHTML } from "components/safe-HTML";
import { validateMessage } from "helpers";
import { useExternalBookingSettings } from "hooks";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Contact, useBookingActionsContext } from "store";

interface IMessageFieldProps {
    control: Control<Contact, any>;
    value: string;
}

const MessageField = ({ control, value }: IMessageFieldProps) => {
    const { t } = useTranslation();
    const externalBookingSettings = useExternalBookingSettings();
    const { setContact } = useBookingActionsContext();

    const headerText = externalBookingSettings?.messageDescription?.length
        ? externalBookingSettings.messageDescription
        : " ";
    const formattedMessageHeaderText = headerText.replace(/\n/g, "<br />");

    const customAddedMessageText = headerText.length > 0 && (
        <SafeHTML
            className="textFromSettings"
            key="messageHeaderTextResult"
            element="span"
            options={{
                allowedTags: ["a", "br"],
                allowedSchemes: ["tel", "mailto", "https"],
            }}
            html={formattedMessageHeaderText}
        />
    );

    const {
        fieldState: { error },
        field,
    } = useController({
        name: "message",
        control,
        rules: {
            validate: (value) => validateMessage(value),
        },
    });

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value.length > 255) return;
        field.onChange(e.target.value);
        setContact({ message: e.target.value });
    };

    return (
        <FormControl isInvalid={!!error}>
            {customAddedMessageText && (
                <Text mb={ThemeSpaceVariable.Medium}>{customAddedMessageText}</Text>
            )}
            <FormLabel>{t("contact.message")}</FormLabel>
            <Textarea
                id="message"
                name="message"
                value={value}
                onChange={handleMessageChange}
                rows={3}
                isInvalid={!!error}
                onBlur={field.onBlur}
            />
            <Flex justify="space-between">
                <FormErrorMessage>{error?.message}</FormErrorMessage>
                <Box
                    as="span"
                    mt={ThemeSpaceVariable.XSmall}
                    color={
                        value?.length > 254
                            ? ThemeColorVariable.Error
                            : ThemeColorVariable.OnBackgroundSubdued
                    }
                    fontSize={ThemeFontSizeVariable.Small}
                >
                    {`${value?.length}/255`}
                </Box>
            </Flex>
        </FormControl>
    );
};

export default MessageField;
