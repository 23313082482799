import { AvailableTimesUnit, WebBookingTimeRule, WebBookingTimeRuleUnit } from "hooks/api/types";

export function mergeTimeSetsByTitle(timeSets: WebBookingTimeRule[]): WebBookingTimeRule[] {
    const timeSetMap: { [key: string]: WebBookingTimeRule } = {};

    timeSets.forEach((timeSet) => {
        const key = timeSet.title;
        const existingTimeSet = timeSetMap[key];
        if (existingTimeSet) {
            // Group by the 'start' property to ensure unique times, then flatten the groups
            const groupedTimes: { [key: string]: WebBookingTimeRuleUnit } = {};

            [...existingTimeSet.times, ...timeSet.times].forEach((time) => {
                const timeKey = time.start;
                groupedTimes[timeKey] = time;
            });
            timeSetMap[key].times = Object.values(groupedTimes);
        } else {
            // Add the current timeSet directly to the map if it's not already there
            timeSetMap[key] = timeSet;
        }
    });

    return Object.values(timeSetMap);
}

export const getRuleById = (ruleId: number, unit: AvailableTimesUnit[]): WebBookingTimeRule => {
    if (!unit) return null;
    return unit
        .flatMap((u: any) => u.sections)
        .flatMap((s: any) => s.timeSets)
        .find((t: any) => t.id === ruleId);
};
