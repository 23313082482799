import { Input, InputProps } from "@chakra-ui/react";
import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    ThemeColorVariable,
} from "caspeco-casper-ui";
import { getPhoneNumberCountryCode, validatePhoneNumber } from "helpers/validation-helper";
import { CountryCode } from "libphonenumber-js/types";
import { forwardRef } from "react";
import { Control, UseFormTrigger, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { Contact, useBookingActionsContext } from "store";

import "react-phone-number-input/style.css";
import "./phone-field.less";

interface ICustomPhoneTextFieldProps {
    value: string;
    disabled?: boolean;
    control: Control<Contact, any>;
    trigger: UseFormTrigger<Contact>;
}

const phoneInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    return <Input size="lg" name="tel" autoComplete="tel" ref={ref} {...props} />;
});

phoneInput.displayName = "PhoneInputElement";

export const PhoneField = ({ value, control, disabled, trigger }: ICustomPhoneTextFieldProps) => {
    const { t } = useTranslation();
    const { setContact } = useBookingActionsContext();

    const countryCode = (
        document.querySelector('select[name="phoneNumberCountry"]') as HTMLSelectElement | null
    )?.value as undefined | CountryCode;

    const {
        field,
        fieldState: { error },
    } = useController({
        control,
        name: "mobile",
        rules: {
            required: t("contact.validation.mobile"),
            validate: {
                validPhoneNumber: (v) => validatePhoneNumber(v, countryCode) as string,
            },
        },
    });

    const handlePhoneChange = (value: string | undefined) => {
        const countrySelect = document.querySelector(
            'select[name="phoneNumberCountry"]'
        ) as HTMLSelectElement | null;
        const countryCode = (countrySelect?.value as CountryCode) ?? undefined;

        field.onChange(value);
        if (error) {
            trigger(field.name);
        }
        setContact({ mobile: value, mobileCountryCode: countryCode });
    };

    return (
        <FormControl isInvalid={!!error}>
            <FormLabel>
                {t("contact.mobile")}{" "}
                <Box as="span" color={ThemeColorVariable.Error}>
                    *
                </Box>
            </FormLabel>
            <PhoneInput
                className="StyledPhoneInput"
                name="phoneNumber"
                autoComplete="tel"
                aria-invalid={Boolean(error)}
                onChange={handlePhoneChange}
                value={value}
                inputComponent={phoneInput}
                defaultCountry={getPhoneNumberCountryCode(field.value)}
                disabled={disabled}
                onBlur={field.onBlur}
                ref={field.ref}
            />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};
