import { parseBackendData } from "api/backendDataParser";
import axios, { AxiosError } from "axios";
import { logError } from "logging/raygunLogger";
import rg4js from "raygun4js";
import { BASE_URL, getUrlParams } from "utils";
const { system } = getUrlParams();
const axiosInstance = axios.create({
    baseURL: `${BASE_URL}/v1`,
    headers: { system: system },
});

type RaygunError = {
    message?: string;
    stack?: string;
    response?: {
        data?: string;
    };
};

/**
 * Send error to Raygun Crash Analytics service.
 * Does not apply for localhost (see Raygun setup in main.tsx).
 */
export function sendErrorToRaygun(error: RaygunError) {
    // Create custom data object with error information

    const customData = {
        message: error.message,
        stack: error.stack,
        error: error,
        reponseData: error.response?.data,
    };

    // Log the error using logError function
    logError(error.message, { customData });

    // Send the error to Raygun
    try {
        rg4js("send", { error: new Error(customData.reponseData), customData });
    } catch (error) {
        console.log("Unable to send error to Raygun!", error);
    }
}

export enum HttpStatusCode {
    OK = 200,
    BadRequest = 400,
    NotFound = 404,
    NoAuth = 403,
    InternalServerError = 500,
    GatewayTimeout = 504,
}

export async function get<T extends Object>(input: string) {
    const response = await axiosInstance.get(input);
    return response.data as T;
}

export async function genericGet<T extends Object>(
    input: string,
    setError?: (error: AxiosError) => void,
    setIsLoading?: (loading: boolean) => void
): Promise<T | null> {
    try {
        const response = await axiosInstance.get(input);
        return parseBackendData(response.data) as T;
    } catch (error) {
        console.log("error", error);
        const err = error?.response as AxiosError;

        if (!setError) setError = () => {};

        switch (err.status) {
            case HttpStatusCode.InternalServerError:
                handleInternalServerError(error, setError);
                break;
            case HttpStatusCode.GatewayTimeout:
                handleGatewayTimeoutError(setError);
                break;
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.NotFound:
                handleClientError(err, setError);
                break;
            default:
                handleUnknownError(error, setError);
                break;
        }

        throw error;
    } finally {
        setIsLoading?.(false);
    }
}

export async function post<TResponse extends Object, TBody>(input: string, body: TBody) {
    const response = await axiosInstance.post(input, body);
    return parseBackendData(response.data) as TResponse;
}

function handleInternalServerError(error: AxiosError, setError: (error: unknown) => void) {
    const requestFailedError = new Error(`Request failed with status 500, code: ${error.response}`);
    sendErrorToRaygun(requestFailedError);
}

function handleGatewayTimeoutError(setError: (error: unknown) => void) {
    const gatewayTimeoutError = new Error("Server did not respond in time (504 Gateway Timeout)");
    sendErrorToRaygun(gatewayTimeoutError);
    setError(gatewayTimeoutError);
}

function handleClientError(error: AxiosError, setError: (error: unknown) => void) {
    const errorMessage =
        error.status === HttpStatusCode.NotFound ? "Resource not found (404)" : "Bad Request (400)";
    const clientError = new Error(errorMessage);
    sendErrorToRaygun(clientError);
    setError(error);
}

function handleUnknownError(error: AxiosError, setError: (error: unknown) => void) {
    const networkError = new Error("Network Error or server did not respond");
    sendErrorToRaygun(networkError);
    setError(networkError);
}
