import { Alert } from "caspeco-casper-ui";
import { SafeHTML } from "components/safe-HTML";
import { AvailableTimesSection, AvailableTimesUnit, WebBookingTimeRule } from "hooks/api/types";
export function BookingClosed({
    units,
    messageBookingClosed,
}: {
    units: AvailableTimesUnit[];
    messageBookingClosed: string;
}) {
    const hasTextRules = units.some((unit: AvailableTimesUnit) => {
        return unit.sections.some((section: AvailableTimesSection) => {
            return section.timeSets.some((timeSet) => {
                return timeSet.isTextRow;
            });
        });
    });

    type SectionWithTextRule = {
        section: AvailableTimesSection;
        timeSet: WebBookingTimeRule;
    };

    const showTextRules = () => {
        // Get sections with text rule
        const rules: SectionWithTextRule[] = [];
        units.forEach((unit: AvailableTimesUnit) => {
            unit.sections.forEach((section: AvailableTimesSection) => {
                section.timeSets.forEach((timeSet) => {
                    section;
                    if (timeSet.isTextRow) {
                        rules.push({ section, timeSet });
                    }
                });
            });
        });

        return rules.map((rule: SectionWithTextRule) => {
            return (
                <Alert
                    key={rule.timeSet.id}
                    title={rule.section.name}
                    type="info"
                    iconVerticalAlign={"flex-start"}
                >
                    <SafeHTML
                        className="textFromSettings"
                        element="div"
                        options={{
                            allowedTags: ["a", "br"],
                            allowedSchemes: ["tel", "mailto", "https"],
                        }}
                        html={rule.timeSet.title}
                    />
                </Alert>
            );
        });
    };

    return (
        <>
            <Alert key="messageBookingClosed" type="info" iconVerticalAlign={"flex-start"}>
                <SafeHTML
                    className="textFromSettings"
                    element="div"
                    options={{
                        allowedTags: ["a", "br"],
                        allowedSchemes: ["tel", "mailto", "https"],
                    }}
                    html={messageBookingClosed}
                />
            </Alert>
            {hasTextRules && showTextRules()}
        </>
    );
}
