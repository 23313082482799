import { Text } from "caspeco-casper-ui";
import { SpaceBetween } from "layout/space-between";

export interface BookingSummaryItemProps {
    label: string;
    value?: string | number;
    children?: JSX.Element;
}

export const BookingSummaryItem = ({ label, value, children }: BookingSummaryItemProps) => {
    return (
        <SpaceBetween>
            <Text flex="0 0 40%">{label}</Text>
            {value && <Text textAlign="end">{value}</Text>}
            {children}
        </SpaceBetween>
    );
};
