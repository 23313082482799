import { Flex, Heading, ThemeSpaceVariable, useDisclosure } from "caspeco-casper-ui";
import BookingConditions from "components/contact-information/booking-conditions";
import { PhoneField } from "components/contact-information/phone-field";
import { PrivacyPolicyModal } from "components/privacy-policy-modal";
import { hasFlag } from "helpers";
import { useExternalBookingSettings } from "hooks";
import { webbookingSettingFlags } from "old/models/unitMetaData";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useBookingActionsContext, useBookingStateContext } from "store";
import { ContactInfoForm } from "types/store";
import CustomerNameField from "./customer-name-field";
import EmailField from "./email-field";
import MessageField from "./message-field";
import NameField from "./name-field";
import NewsletterField from "./newsletter-field";
import PrivacyPolicyText from "./privacy-policy-text";
import SubmitButton from "./submit-button";

export const ContactInformation = () => {
    const { t } = useTranslation();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const externalBookingSettings = useExternalBookingSettings();
    const state = useBookingStateContext();
    const { handleFinalize, finalize } = useBookingActionsContext();
    const contact = state.contact || {};

    const { control, handleSubmit, formState, trigger } = useForm<ContactInfoForm>({
        mode: "onBlur",
        defaultValues: {
            ...contact,
        },
    });

    const displayMessageBox = externalBookingSettings && externalBookingSettings.displayMessageBox;
    const unitMetaData = externalBookingSettings.unitsMetaData;
    const showCustomerNameField = hasFlag(
        unitMetaData.binaryFlags,
        webbookingSettingFlags.displayCustomerName
    );

    const onSubmit = () => handleFinalize();
    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <BookingConditions />
            <Heading as="h5" fontWeight="500" mt={ThemeSpaceVariable.Large}>
                {t("contactDetails")}
            </Heading>
            <Flex direction="column" gap={ThemeSpaceVariable.Medium} mt={ThemeSpaceVariable.Medium}>
                <NameField
                    value={contact.fname || ""}
                    control={control}
                    variant="fname"
                    // disabled={!!contactLoadedByFriendlyId?.fname}
                    trigger={trigger}
                />
                <NameField
                    value={contact.lname || ""}
                    control={control}
                    variant="lname"
                    // disabled={!!contactLoadedByFriendlyId?.lname}
                    trigger={trigger}
                />
                <PhoneField
                    value={contact.mobile || ""}
                    control={control}
                    // disabled={!!contactLoadedByFriendlyId?.mobile}
                    trigger={trigger}
                />
                <EmailField
                    value={contact.email || ""}
                    control={control}
                    // disabled={!!contactLoadedByFriendlyId?.email}
                    trigger={trigger}
                />

                {showCustomerNameField && <CustomerNameField control={control} />}

                {displayMessageBox && (
                    <MessageField control={control} value={contact.message || ""} />
                )}

                {externalBookingSettings.newsletter && <NewsletterField />}

                <PrivacyPolicyText onOpen={onOpen} />
                <PrivacyPolicyModal isOpen={isOpen} onClose={onClose} />
            </Flex>
            <SubmitButton isLoading={finalize.isPending} isDisabled={!formState.isValid} />
        </form>
    );
};
