import { Flex, Heading, Icon, Icons, ThemeSpaceVariable } from "caspeco-casper-ui";

interface IChoiceHeaderProps {
    id: string;
    icon: Icons;
    text: string;
}

const SelectionHeader = ({ id, icon, text }: IChoiceHeaderProps) => {
    return (
        <Flex
            alignItems="center"
            gap={ThemeSpaceVariable.Small}
            my={ThemeSpaceVariable.Large}
            userSelect="none"
        >
            <Icon alignSelf="flex-start" icon={icon} fontSize="30px" />
            <Heading as="h6" id={id}>
                {text}
            </Heading>
        </Flex>
    );
};

export default SelectionHeader;
