import { HttpStatusCode } from "api/apiMethods";
import BrokenLink from "components/error-components/broken-link";
import ServerDidNotRespond from "components/error-components/server-did-not-respond";
import SomethingWrong from "components/error-components/something-wrong";
import { useError } from "context/error-context";

export function ErrorView() {
    const { error } = useError();

    const status = error?.status
        ? error.status
        : error?.response?.status
          ? error?.response?.status
          : null;

    switch (status) {
        case HttpStatusCode.NotFound:
            if (error?.data === "Unit does not exist") return <BrokenLink />;
            return <SomethingWrong />;
        case HttpStatusCode.InternalServerError:
            return <BrokenLink />;
        case HttpStatusCode.GatewayTimeout:
            return <ServerDidNotRespond />;
        default:
            return <SomethingWrong />;
    }
}
