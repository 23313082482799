import { Moment } from "moment";
import { Time } from "types/store";
import { getRuleById } from "utils/time-rule-utils";
import { useAvailableTimes } from "./api";

/**
 * Get the selected time text formatted - for single time as an intervall
 * and for multiple times with a plus sign and the number of additional times.
 *
 * @param {Time[]} time - The time slots that has been selected.
 * @returns {string}
 */
export const useTimeText = (times: Time[], date: Moment): string => {
    const availableTimes = useAvailableTimes(date);
    const ruleById = getRuleById(times ? times[0]?.ruleId : -1, availableTimes.data);

    if (!times || times.length === 0) return "";

    const sortedArray = times.sort((a, b) => a.start.diff(b.start));

    let formattedTime = `${sortedArray[0].start.format("HH:mm")}`;

    if (times.length > 1) {
        formattedTime = `${sortedArray[0]?.start.format("HH:mm")} +${times?.length - 1}`;
    } else if (times.length > 0) {
        const endTime = times[times.length - 1].end
            ?.clone()
            .subtract(ruleById?.recoupTime, "minute")
            .format("HH:mm");
        formattedTime += ` - ${endTime}`;
    }

    formattedTime = formattedTime.replace(/\s+/g, "");
    return formattedTime;
};

export default useTimeText;
