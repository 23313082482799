if ("__APP_START_TIME__" in window) {
    const now = new Date();
    const loadTime = now.getTime() - window.__APP_START_TIME__.getTime();
    console.log("Start loading main.tsx: ", loadTime);
}

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from "app";
import { trackPageView } from "logging/insights";
import { RAYGUN_API_KEY } from "logging/raygunConfig";
import { logError } from "logging/raygunLogger";
import queryClient from "query-client";
import rg4js from "raygun4js";
import React from "react";
import ReactDOM from "react-dom/client";
import "translations/config/i18n";
import { getUrlParams } from "utils";
import "utils/load-script";

const { system } = getUrlParams();

rg4js("apiKey", RAYGUN_API_KEY);
rg4js("enableCrashReporting", true);

rg4js("withTags", [`system:${system}`]);
rg4js("options", {
    captureUnhandledRejections: true,
    allowInsecureSubmissions: true,
    excludedHostnames: ["localhost"],
});

window.addEventListener("error", (event) => {
    logError(event.error);
});

trackPageView("Booking", window.location.pathname);

if ("__APP_START_TIME__" in window) {
    const now = new Date();
    const loadTime = now.getTime() - window.__APP_START_TIME__.getTime();
    console.log("Before React Render: ", loadTime);
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} position="right" />
        </QueryClientProvider>
    </React.StrictMode>
);
