import { Button } from "caspeco-casper-ui";
import { useWebBooking } from "hooks";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { getUrlParams } from "utils";
import { hasBookingPassed, isWaitListOfferLocked } from "utils/booking";

const BookMoreButton = () => {
    const location = useLocation();
    const isFinal = location.state?.isFinal;

    const { data: webBooking } = useWebBooking();

    const hasPassed = hasBookingPassed(webBooking);
    const isOfferLocked = isWaitListOfferLocked(webBooking);

    const { system } = getUrlParams();
    const offerLocked = isOfferLocked && !hasPassed && !isFinal;
    const alignment = offerLocked ? "center" : "flex-end";
    return (
        <Button
            variant="tertiary"
            alignSelf={alignment}
            href={`${window.location.origin}/${system}/${webBooking.unitId}`}
        >
            {offerLocked ? t("toBookingStart") : t("bookMore")}
        </Button>
    );
};

export default BookMoreButton;
