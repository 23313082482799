import { ScaleFade } from "@chakra-ui/react";
import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Switch,
    ThemeColorVariable,
} from "caspeco-casper-ui";
import { validateCustomerName } from "helpers";
import { ChangeEvent, useState } from "react";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ContactInfoForm, useBookingActionsContext, useBookingStateContext } from "store";

interface ICustomerNameFieldProps {
    control: Control<ContactInfoForm, any>;
}

const CustomerNameField = ({ control }: ICustomerNameFieldProps) => {
    const { t } = useTranslation();
    const { setCustomer } = useBookingActionsContext();
    const { customer } = useBookingStateContext();

    const [isCompanyCustomer, setIsCompanyCustomer] = useState(false);

    const {
        fieldState: { error },
        field,
    } = useController({
        name: "name",
        control,
        rules: {
            required: isCompanyCustomer ? t("customer.customerName") : false,
            validate: isCompanyCustomer ? (value) => validateCustomerName(value) : undefined,
        },
    });

    const handleCustomerTypeToggle = (e: React.ChangeEvent<HTMLInputElement>) =>
        setIsCompanyCustomer(e.target.checked);

    const handleCustomerNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        field.onChange(e.target.value);
        setCustomer({ name: value });
    };

    return (
        <>
            <FormControl>
                <Flex direction="row" align="center">
                    <Switch
                        isChecked={isCompanyCustomer}
                        onChange={handleCustomerTypeToggle}
                        id="isCompanyCustomer"
                        color="primary"
                    />
                    <Box ml="10px" pt="5px">
                        <FormLabel>{t("customer.addCustomerName")}</FormLabel>
                    </Box>
                </Flex>
            </FormControl>
            {isCompanyCustomer && (
                <ScaleFade in={isCompanyCustomer}>
                    <FormControl isInvalid={!!error}>
                        <FormLabel>
                            {t("customer.name")}{" "}
                            <Box as="span" color={ThemeColorVariable.Error}>
                                *
                            </Box>
                        </FormLabel>
                        <Input
                            size="lg"
                            id="customerName"
                            name="customerName"
                            autoComplete="customerName"
                            onChange={handleCustomerNameChange}
                            value={customer?.name || ""}
                            onBlur={field.onBlur}
                            ref={field.ref}
                        />
                        <FormErrorMessage>{error?.message}</FormErrorMessage>
                    </FormControl>
                </ScaleFade>
            )}
        </>
    );
};

export default CustomerNameField;
