import { post } from "api/apiMethods";
import { WebBooking } from "api/models/webBooking";
import { WebBookingErrorCode, useError } from "context/error-context";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { GUID_STORAGE_KEY } from "store";
import i18n from "translations/config/i18n";
import { State } from "types/store";
import { CreateBookingParameters } from "./types";

export const useCreateReservation = () => {
    const { t } = useTranslation();
    const { setErrorCode, openReservationFailedModal } = useError();

    const createReservation = async (state: State) => {
        const data: CreateBookingParameters = {
            ...state,
            guestsChildren: state.guestsChildren || 0,
            times: state.times.map((time: any) => {
                return {
                    ruleId: time.ruleId,
                    start: time.start.format("YYYY-MM-DD[T]HH:mm:ss"),
                };
            }),
            timeSetDate: state.date.format("YYYY-MM-DD[T]HH:mm:ss"),
            lang: i18n.language,
            isPending: true,
            isWaitList: state.isWaitList ? true : false,
            logMessage: t("changeLogMessage.pendingLogMessage"),
        };

        return post<WebBooking, CreateBookingParameters>("WebBooking/WebBookings", data).then(
            (reservation) => {
                localStorage.setItem(GUID_STORAGE_KEY, reservation.guid);
            }
        );
    };

    return useMutation({
        mutationFn: createReservation,
        scope: {
            id: "webbooking-create",
        },
        onError: (data: any) => {
            const errorCode = data.response.data.code;
            if (errorCode && errorCode !== WebBookingErrorCode.Undefined) {
                setErrorCode(errorCode);
                openReservationFailedModal();
            }
        },
    });
};
