import { useUpdateEffect } from "@chakra-ui/react";
import { restaurantNamePromise, useExternalBookingSettingsQuery } from "api/apiHooks";
import {
    Box,
    Flex,
    Heading,
    Image,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useEffect, useState } from "react";
import { CSSVarHeaderHeight } from "style-variables";
import { HamburgerButton } from "./hamburger-button";

const waitForRestaurantName = async (
    setName: (value: ((prevState: string) => string) | string) => void
) => {
    const name = await restaurantNamePromise;
    setName(name);
};

interface IHeaderProps {
    shouldHideCoverImage: boolean;
    hasCoverImage: boolean;
}

const Header = ({ shouldHideCoverImage, hasCoverImage }: IHeaderProps) => {
    const { isLoading, error, data } = useExternalBookingSettingsQuery();

    const [name, setName] = useState("");
    const [hasScrolled, setHasScrolled] = useState(false);

    function onScroll() {
        const newValue = window.scrollY > 0;
        if (newValue !== hasScrolled) setHasScrolled(newValue);
    }
    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, []);

    useUpdateEffect(() => {
        waitForRestaurantName(setName);
    });

    const getHeaderBGColor = () => {
        let bgColor = "none";

        if (shouldHideCoverImage || hasScrolled || !hasCoverImage) {
            bgColor = "var(--theme-colors-primary-wlv)";
        }

        return bgColor;
    };

    return !isLoading && data ? (
        <Flex
            as="header"
            position="fixed"
            top="0"
            width="100%"
            zIndex="3"
            h={CSSVarHeaderHeight}
            p={ThemeSpaceVariable.Medium}
            justifyContent="space-between"
            alignItems="center"
            backgroundColor={getHeaderBGColor()}
            transition={!shouldHideCoverImage ? "background-color 0.2s ease-in-out" : ""}
        >
            <Box display="flex" flex={1} justifyContent="flex-start">
                {!error && <HamburgerButton />}
            </Box>

            {data.themeSettings?.logo.imageUrl ? (
                <Flex justifyContent="center" flex={3}>
                    <Image
                        src={data.themeSettings.logo.imageUrl}
                        zIndex="2"
                        maxHeight={CSSVarHeaderHeight}
                    />
                </Flex>
            ) : (
                <Flex justifyContent="center" flex={3}>
                    <Heading
                        as="h1"
                        color={ThemeColorVariable.OnPrimary}
                        fontSize={{
                            base: ThemeFontSizeVariable.XLarge,
                            md: ThemeFontSizeVariable.X2Large,
                        }}
                        fontFamily={ThemeFontVariable.Header}
                        fontWeight="bold"
                        textAlign="center"
                        noOfLines={2}
                    >
                        {name}
                    </Heading>
                </Flex>
            )}
            <Flex flex="1" />
        </Flex>
    ) : null;
};

export default Header;
