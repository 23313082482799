import { Checkbox, FormControl } from "caspeco-casper-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBookingActionsContext, useBookingStateContext } from "store";

const NewsletterField = () => {
    const { t } = useTranslation();
    const { setContact } = useBookingActionsContext();
    const { contact } = useBookingStateContext();

    const handleNewsletterToggle = (event: React.ChangeEvent<HTMLInputElement>) =>
        setContact({ newsletter: event.target.checked });
    return (
        <FormControl>
            <Checkbox
                isChecked={contact?.newsletter}
                onChange={handleNewsletterToggle}
                name="newsletter"
                color="primary"
            >
                {t("contact.newsletterSignup")}
            </Checkbox>
        </FormControl>
    );
};

export default NewsletterField;
