import { useQuery } from "@tanstack/react-query";
import { ExternalBookingSettings } from "old/models/externalBookingSettings";
import { useParams } from "react-router-dom";
import { get } from "./axios-base";

export function useExternalBookingSettings() {
    const { unitId } = useParams();
    return useQuery({
        queryKey: ["externalBookingSettings", unitId],
        queryFn: async () => {
            const response = await get(`/booking/externalBookingSettings/${unitId}`);
            return response.data as ExternalBookingSettings;
        },
    });
}
