import { List } from "immutable";
import Action from "old/framework/actions/action";
import { WebBooking } from "old/models/webBooking";
import { IWebBookingContact } from "old/models/webBookingContact";
import { IWebBookingCreateParameters } from "old/models/webBookingCreateParameters";
import { PaymentMethodType } from "old/models/webBookingPaymentTerminalParameters";
import { WebBookingSection } from "old/models/webBookingSection";
import { WebBookingStep } from "old/models/webBookingStep";
import { WebBookingTimeRuleUnit } from "old/models/webBookingTimeRuleUnit";

export class Initialize extends Action {
    static actionType: string = "webBookingView_Initialize";

    constructor(
        public unitId: number = null,
        public webBookingId: string = null,
        public system: string = null
    ) {
        super(false);
        this.doDispatch();
    }
}

export class Reset extends Action {
    static actionType: string = "webBookingView_Reset";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class SetActiveStepLoading extends Action {
    static actionType: string = "webBookingView_SetActiveStepLoading";
    constructor() {
        super(false);
        this.doDispatch();
    }
}
export class ChangeDate extends Action {
    static actionType: string = "webBookingView_ChangeDate";

    constructor(public date: IMoment) {
        super(false);
        this.doDispatch();
    }
}

export class ChangeSectionFilter extends Action {
    static actionType: string = "webBookingView_ChangeSectionFilter";

    constructor(public sections: List<WebBookingSection>) {
        super(false);
        this.doDispatch();
    }
}
export class ChangeFromTime extends Action {
    static actionType: string = "webBookingView_ChangeFromTime";

    constructor(public fromTime: IMoment) {
        super(false);
        this.doDispatch();
    }
}

export class NextStep extends Action {
    static actionType: string = "webBookingView_NextStep";

    constructor(public onFinalize?: () => void) {
        super(false);
        this.doDispatch();
    }
}

export class SetStep extends Action {
    static actionType: string = "webBookingView_SetStep";

    constructor(public step: WebBookingStep) {
        super(false);
        this.doDispatch();
    }
}

export class PrevStep extends Action {
    static actionType: string = "webBookingView_PrevStep";

    constructor() {
        super();
    }
}

export class SkipStep extends Action {
    static actionType: string = "webBookingView_SkipStep";

    constructor() {
        super();
    }
}

export class ChangeModelField extends Action {
    static actionType: string = "webBookingView_ChangeModelField";

    constructor(
        public fieldName: keyof IWebBookingCreateParameters,
        public fieldValue: any
    ) {
        super(false);
        this.doDispatch();
    }
}

export class ChangePaymentMethod extends Action {
    static actionType: string = "webBookingView_ChangePaymentMethod";

    constructor(public Value: PaymentMethodType) {
        super(false);
        this.doDispatch();
    }
}

export class ChangeContactField extends Action {
    static actionType: string = "webBookingView_ChangeContactField";

    constructor(
        public fieldName: keyof IWebBookingContact,
        public fieldValue: any
    ) {
        super(false);
        this.doDispatch();
    }
}

export class TimeClicked extends Action {
    static actionType: string = "webBookingView_TimeClicked";

    constructor(
        public time: WebBookingTimeRuleUnit,
        public sectionIds: number[],
        public debug: boolean,
        public hostUrl: string
    ) {
        super(false);
        this.doDispatch();
    }
}
export class WaitListTimeClicked extends Action {
    static actionType: string = "webBookingView_WaitListTimeClicked";

    constructor(public times: List<WebBookingTimeRuleUnit>) {
        super(false);
        this.doDispatch();
    }
}

export class PostMenu extends Action {
    static actionType: string = "webBookingView_PostMenu";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class CancelBooking extends Action {
    static actionType: string = "webBookingView_CancelBooking";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class GetPaymentTerminal extends Action {
    static actionType: string = "webBookingView_GetPaymentTerminal";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class ChangeMenuField extends Action {
    static actionType: string = "webBookingView_ChangeMenuField";

    constructor(
        public path: any,
        public value: any,
        public itemPath?: any
    ) {
        super(false);
        this.doDispatch();
    }
}

export class CreateWebBooking extends Action {
    static actionType: string = "webBookingView_CreateWebBooking";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class WebBookingCreatedSuccessfully extends Action {
    static actionType: string = "webBookingView_WebBookingCreatedSuccessfully";

    constructor(public booking: WebBooking) {
        super(false);
        this.doDispatch();
    }
}

export class CreateWebBookingFailed extends Action {
    static actionType: string = "webBookingView_CreateWebBookingFailed";

    constructor(public ex: any) {
        super(false);
        this.doDispatch();
    }
}

export class FirstEventTime extends Action {
    static actionType: string = "webBookingView_FirstEventTime";

    constructor() {
        super(false);
        this.doDispatch();
    }
}
export class ResetSelectedTimes extends Action {
    static actionType: string = "webBookingView_ResetSelectedTimes";

    constructor() {
        super(false);
        this.doDispatch();
    }
}
export class ResetActiveStepValidation extends Action {
    static actionType: string = "webBookingView_ResetActiveStepValidation";

    constructor() {
        super(false);
        this.doDispatch();
    }
}
