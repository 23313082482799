import { GridItem } from "@caspeco/casper-ui-library.components.grid";
import { useDisclosure } from "@chakra-ui/react";
import { Box, ThemeSpaceVariable } from "caspeco-casper-ui";
import WaitlistModal from "components/wait-list/wait-list-modal";
import WaitListTimeSlot from "components/wait-list/wait-list-time-slot";
import React, { useState } from "react";
import { useBookingActionsContext, useBookingStateContext } from "store";
import { Time } from "types/store";
import { WebBookingTimeRule, WebBookingTimeRuleUnit } from "hooks/api/types";
import { getSortedHourKeys, groupedTimes } from "utils/booking";
import TextRow from "./text-row";
import TimeSetComment from "./time-set-comment";
import TimeSetTitle from "./time-set-title";
import TimeSlot from "./time-slot";
import TimeSlotGrid from "./time-slot-grid";
import WaitListTimeSetTitle from "./wait-list-time-set-title";

interface TimeSetProps {
    timeSet: WebBookingTimeRule;
}

function TimeSet(props: TimeSetProps) {
    const state = useBookingStateContext();
    const { guests } = state;

    const { isOpen, onClose, onOpen } = useDisclosure();
    const { setIsWaitList, handleReservation } = useBookingActionsContext();

    const [selectedWaitListTimes, setSelectedWaitListTimes] = useState<WebBookingTimeRuleUnit[]>(
        []
    );

    const handleWaitlistClick = (time: WebBookingTimeRuleUnit) => {
        if (!isOpen) {
            setIsWaitList(true);
            onOpen();
        }

        const timeIndex = selectedWaitListTimes.indexOf(time);

        let newSelectedTimes;

        if (timeIndex > -1) {
            newSelectedTimes = selectedWaitListTimes.splice(timeIndex, 1);
        } else {
            newSelectedTimes = [...selectedWaitListTimes, time];
        }

        setSelectedWaitListTimes(newSelectedTimes);

        if (newSelectedTimes.length === 0) {
            onClose();
            setIsWaitList(false);
        }
    };

    const handleWaitlistModalClose = () => {
        onClose();
        setSelectedWaitListTimes([]);
    };

    const handleWaitlistContinue = () => {
        const stateWithTimes = {
            ...state,
            times: selectedWaitListTimes as Time[],
        };

        handleReservation(stateWithTimes);
    };

    function getSlotsAsSortedGridItems(
        times: WebBookingTimeRuleUnit[],
        isWaitListSet = false,
        selectWaitListSlots = false
    ) {
        const itemList: React.ReactNode[] = [];

        let slotsFilledInCurrentRow = 0;
        let itemCount = 1;

        const magicNumber = 4;

        const groupedTimesByHour = groupedTimes(times);
        const hourKeys = getSortedHourKeys(Object.keys(groupedTimesByHour));

        hourKeys?.forEach((hour, index) => {
            const timesThisHour = groupedTimesByHour[hour];
            const nextHourTimes = groupedTimesByHour[hourKeys[index + 1]] || [];

            timesThisHour?.forEach((time: WebBookingTimeRuleUnit, timeIndex: number) => {
                const row = Math.ceil(itemCount / magicNumber);
                const column = itemCount % magicNumber;

                itemList.push(
                    <GridItem
                        key={`time-${time.ruleId}-${time.start.format("HHmm")}`}
                        gridColumn={column}
                        gridRow={row}
                    >
                        {isWaitListSet ? (
                            <WaitListTimeSlot
                                onTimeClick={handleWaitlistClick}
                                selectedTimes={selectWaitListSlots ? selectedWaitListTimes : []}
                                time={time}
                            />
                        ) : (
                            <TimeSlot
                                key={`webBookingTime-${time.ruleId}-${time.start.format("HHmm")}`}
                                time={time}
                                timeSet={timeSet}
                            />
                        )}
                    </GridItem>
                );

                slotsFilledInCurrentRow = itemCount % magicNumber;
                const isLastTimeInHour = timeIndex === timesThisHour.length - 1;
                if (isLastTimeInHour) {
                    const remainingSlotsInCurrentRow = magicNumber - slotsFilledInCurrentRow;

                    if (!(nextHourTimes.length <= remainingSlotsInCurrentRow)) {
                        itemCount += remainingSlotsInCurrentRow;
                    }
                }
                itemCount++;
            });
        });

        return itemList;
    }

    const timeSet = props.timeSet;

    const waitListTimes = timeSet.times.filter((time) => {
        return time?.waitListSeats?.includes(guests);
    });

    const bookableTimes = timeSet.times.filter((time) => {
        return time.availableSeats.includes(guests);
    });

    const regularSet = getSlotsAsSortedGridItems(bookableTimes);
    const waitListSet = getSlotsAsSortedGridItems(waitListTimes, true);

    if (timeSet.times.length < 1 && timeSet.isTextRow === false) return null;

    if (timeSet.isTextRow && !timeSet.isClosed) {
        return <TextRow title={timeSet.title} comment={timeSet.comment} />;
    }

    const noAvailableSlots = regularSet.length === 0 && waitListSet.length === 0;
    if (noAvailableSlots) return null;

    return (
        <>
            <Box mb={ThemeSpaceVariable.Large} px={ThemeSpaceVariable.Medium}>
                <TimeSetTitle title={timeSet.title} />
                {regularSet.length > 0 && (
                    <>
                        {timeSet.comment && <TimeSetComment comment={timeSet.comment} />}
                        <TimeSlotGrid timeSlots={regularSet} />
                    </>
                )}
                {waitListSet.length > 0 && (
                    <Box mt={ThemeSpaceVariable.Medium}>
                        <WaitListTimeSetTitle />
                        <TimeSlotGrid timeSlots={waitListSet} />
                    </Box>
                )}
            </Box>
            <WaitlistModal
                isOpen={isOpen}
                handleWaitlistContinue={handleWaitlistContinue}
                handleWaitlistModalClose={handleWaitlistModalClose}
                waitListTimes={waitListTimes}
                selectedTimes={selectedWaitListTimes}
                onTimeClick={handleWaitlistClick}
            />
        </>
    );
}

export default React.memo(TimeSet);
