import { post } from "api/apiMethods";
import { useMutation } from "@tanstack/react-query";
import { GUID_STORAGE_KEY } from "store";

export const useCancelBooking = () => {
    const cancelBooking = (guid: string) => post(`WebBooking/WebBookings/${guid}/Cancel`, {});

    return useMutation({
        mutationFn: cancelBooking,
        onSuccess: () => {
            localStorage.removeItem(GUID_STORAGE_KEY);
        },
    });
};
